import React from 'react';

const Footer = props =>
    <div className="footer clearfix">
        <div className="container">
            {props.children}
        </div>
    </div>

export default Footer;
