import React from 'react';

const VideoBanner = props =>
    <React.Fragment>
        <video playsInline={true} autoPlay="autoplay" muted="muted" loop="loop">
            <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4"/>
        </video>
    </React.Fragment>

export default VideoBanner;
