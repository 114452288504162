import React from 'react';
import Indicators from './indicators';
import CarouselItem from './item';

//<CarouselItem className="active" video={VIDEO_SOURCE}>

const Carousel = props =>
    <section id={props.id} className="carousel slide page-top" data-bs-ride="carousel">
        {props.indicators && <Indicators target={props.id} items={props.items}/>}
        <div className="carousel-inner">
            {props.items.map((item, index) =>
                <CarouselItem item={item}
                              className={!index ? "active" : ""}
                              alt={`Carousel Item ${index}`}
                              key={`carousel-${index}`}
                >
                    <h1>{item.title}</h1>
                    <p>{item.caption}</p>
                </CarouselItem>
            )}
        </div>
        {props.controls && <button className="carousel-control-prev"
                              type="button"
                              data-bs-target={`#${props.id}`}
                              data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                <span className="visually-hidden">Previous</span>
                            </button>}
        {props.controls && <button className="carousel-control-next"
                              type="button"
                              data-bs-target={`#${props.id}`}
                              data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true"/>
                                <span className="visually-hidden">Next</span>
                            </button>}
    </section>

export default Carousel;
