/**
 * This file provides some events related to the windows scroll position and how it relates to the displayed
 * documents sections.
 *
 * EVENT_SECTION_CHANGED is raised whenever the active document section has changed.
 * EVENT_TOP_CHANGED is raised whenever the top status of the document has changed.
 */
import $ from 'jquery';

let pending = false;
let isTop = true;

const EVENT_MAP = {};

const REFRESH_DELAY_MS = 100;

export const EVENT_SECTION_CHANGED = 'event:changed';
export const EVENT_TOP_CHANGED = 'event:top';

/**
 * Adds a callback method that is invoked when
 * @param {string} id
 * @param {function} cb
 */
export function addListener(id, cb) {
    if (!id || typeof id !== 'string') {
        throw new Error('Cannot add listener for invalid event identifier');
    }

    if (!EVENT_MAP[id]) {
        EVENT_MAP[id] = [];
    }

    EVENT_MAP[id].push(cb);
}

/**
 *
 * @param id
 * @param cb
 */
export function removeListener(id, cb) {
    if (!id || typeof id !== 'string') {
        throw new Error('Cannot remove listener for invalid event identifier');
    }

    if (EVENT_MAP[id]) {
        EVENT_MAP[id] = EVENT_MAP[id].filter(item => item !== cb);
    }
}

/**
 * Changes the current navigation url to the element associated with the supplied identifier.
 * @param {string} id - Identifier of the element to be navigated to.
 */
export function navigate(id) {
    if (id && window.location.hash !== id) {
        window.history.replaceState(null, null, id);
        const items = $(id);
        if (items.length > 0) {
            items[0].scrollIntoView();
        }
        raise(EVENT_SECTION_CHANGED);
    }
}

function raise(id) {
    if (!id || typeof id !== 'string') {
        throw new Error('Cannot raise event with invalid identifier');
    }

    if (EVENT_MAP[id]) {
        EVENT_MAP[id].forEach(item => item());
    }
}

/**
 * Determines how far away an element is from the top of the display. The distance is also adjusted by an amount
 * proportional to the elements height. This is used to allow the next element to become active before the previous
 * element has mostly disappeared.
 * @param el - The HTML element whose distance is to be computed.
 * @returns {number} The distance of the HTML element from the top of the displayed window.
 */
function elementDistance(el, scan) {
    // select the rectangle whose start is inside the window and nearest the top.
    const rect = el.getBoundingClientRect();

    const top = Math.max(rect.top, scan.top);
    const bottom = Math.min(Math.max(rect.bottom, 0), scan.bottom);
    const height = Math.max(bottom - top, 0);

    return height / rect.height;
}

function detectActiveSection() {
    pending = false;

    const IS_TOP = window.scrollY === 0;
    if (IS_TOP !== isTop) {
        isTop = IS_TOP;
        raise(EVENT_TOP_CHANGED);
    }

    if (window.history.pushState) {
        const scan = {
            top: 0,//window.scrollY,
            center: window.innerHeight * 0.5,//window.scrollY + window.innerHeight * 0.5,
            bottom: Math.max(window.innerHeight, 10), //window.scrollY + window.innerHeight,
            topDistance: Number.POSITIVE_INFINITY,
            topElement: null,
            bottomDistance: Number.POSITIVE_INFINITY,
            bottomElement: null,
        };

        let nearest = 0;
        let id = null;

        $('section').each(function(index, el) {
            const d = elementDistance(el, scan);
            if (d > nearest) {
                id = '#' + $(el).attr('id');
                nearest = d;
            }
        });

        if (id && window.location.hash !== id) {
            window.history.replaceState(null, null, id);
            raise(EVENT_SECTION_CHANGED);
        }
    }
}

document.addEventListener('scroll', () => {
    if (!pending) {
        pending = true;
        setTimeout(detectActiveSection, REFRESH_DELAY_MS);
    }
});
