import React from 'react';
import Footer from '../components/footer';
import NavBar from '../components/nav/navbar';
import Carousel from '../components/carousel';
import SlidingWindow from '../components/sliding_window';

import NAV_ITEMS from './navbar_items.json';
import ITEMS from './carousel_items.json';
/*
    Old Video entry (for future reference)
  {
    "title": "Video",
    "caption": "Pictures from some of the most beautiful locations in the UK.",
    "video": "https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4",
    "image": ""
  },
 */

const Application = () =>
    <React.Fragment>
        <div className="clearfix home-yt">
            <NavBar id={'nav-main'} items={NAV_ITEMS}/>
        </div>
        <Carousel id="home" indicators={true} controls={true} items={ITEMS}/>
        <section id="about" className="container content">
            <i className="fa fa-handshake content-icon d-block text-dark"/>
            <p className="top-title text-dark">About</p>
            <h2 className="content-title text-dark">Who we are</h2>
            <div className="row">
                <div className="col-md-7">
                    <img className="rounded-18 img-shadow img-fluid" src="/about_thumb.jpg" alt="About"/>
                </div>
                <div className="col-md-5">
                    <p className="line-subtext">Your dreams and ideas, illuminated</p>
                    <h3 className="content-subtitle">What to expect</h3>
                    <p>We love to create images and videos that bring your own vision to life, in a way
                        that is stunningly visceral.
                    </p>
                    <p>Whether it's a studio portrait for your own personal branding, a product you wish
                        to show-off or a video to introduce your new product to the masses, we are here to breath life into your
                        ideas.
                    </p>
                    <p>At Lumic Studio we believe in quality throughout the entire production. From lighting to audio,
                        from composition to subject. We aim to bring you the highest quality and value
                        possible that showcases your own dreams and ideas in a way to bring your ideas to life for
                        the entire world to see.
                    </p>
                    <p>
                        We are a friendly, fun and enthusiastic team. Whose only desire is to get you the best media for
                        your needs.
                    </p>
                </div>
            </div>
        </section>
        <SlidingWindow id="portrait" image="/portraits_bg.jpg">
            <i className="fas fa-camera content-icon d-block"/>
            <p className="top-title">Portrait</p>
            <p className="quote-text text-light">Personal, Actor or Professional</p>
            <div className="row text-light">
                <div className="col-md-6">
                    <p className="line-subtext">Capturing Personality</p>
                    <p>
                        For a personal portrait, your photographer will talk to you while taking photographs. This
                        conversation could be about almost anything! But will generally be about a subject you enjoy.
                        This approach allows us to achieve a portrait where your true personality and feelings can
                        shine through as well as allow you to get accustomed to the camera, for a natural yet
                        intimate portrait.
                    </p>
                    <p>
                        If you are an actor looking for headshots to be used for casting, our photographer will
                        work with you to create a set of headshot that demonstrate your range as well as give a
                        true representation of how you really look.
                    </p>
                    <p>
                        For professionals, making a great first impression is of the utmost importance. More
                        often than not, your headshot is the first time someone will see you. Your photographer
                        will work with you to produce a headshot that conveys not only yourself but your brand too.
                    </p>
                    <p>
                        We love taking photographs of people! If you have a requirement that does not fit directly
                        into the above categories get in touch! We would love to hear your ideas and how we can help
                        bring them to life.
                    </p>
                </div>
                <div className="col-md-6">
                    <div className="text-center">
                        <img className="rounded border-dark img-shadow-dark d-block" src="/personal_portrait.jpg" alt="Portrait Photography"/>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <a className="slide-btn" target="_blank" rel="noopener noreferrer" href="https://lumicstudio.shootproof.com/gallery/test_portfolio/">Portfolio</a>
                </div>
            </div>
        </SlidingWindow>
        <section id="performance" className="container content">
            <i className="fa-solid fa-people-line content-icon d-block text-dark"/>
            <p className="top-title text-dark">Performance Photography</p>
            <div className="row">
                <div className="col-md-7">
                    <img className="rounded border-dark img-shadow-dark" src="/fire_show_3.jpg" alt="Product Photography"/>
                </div>
                <div className="col-md-5 mt-4">
                    <h3 className="content-subtitle">Your Dedication. Captured.</h3>
                    <p>
                        Drama, dance, music... and anything in-between! We love capturing people doing the things
                        they love most.
                    </p>
                    <p>
                        Keenly aware of the dedication you have invested in your art, we aim to capture in a way
                        that demonstrates your talent to the highest level.
                    </p>
                    <p>
                        Whether you need a private photography session, photography of your rehearsal or while
                        you are live on stage! We can be there to ensure your performance is captured in all its
                        glory.
                    </p>
                    <a className="slide-btn" href="#contact">Contact Us!</a>
                </div>
            </div>
        </section>
        <SlidingWindow id="events" image="/events_bg.jpg">
            <i className="fa-solid fa-calendar-check content-icon d-block"/>
            <p className="top-title">Events</p>
            <p className="quote-text text-light">Indoor, Outdoor, Public or Private</p>
            <div className="row mb-4">
                <div className="col-md-4">
                    <img className="rounded border-dark img-shadow-dark" src="/events_1.jpg" alt="Party Photography"/>
                </div>
                <div className="col-md-4">
                    <img className="rounded border-dark img-shadow-dark" src="/events_2.jpg" alt="Party Photography"/>
                </div>
                <div className="col-md-4">
                    <img className="rounded border-dark img-shadow-dark" src="/events_3.jpg" alt="Party Photography"/>
                </div>
            </div>
            <div className="row justify-content-center text-light">
                <h3 className="content-subtitle text-light">Parties & Events</h3>
                <p>
                    Whether its a private party or a corporate shindig, sometimes you just want those moments captured.
                    Perhaps you have a show and expecting people from across the country!
                </p>
                <p>
                    Our friendly photographers can be there to capture all those moments you missed as well as those
                    moment you want to remember.
                </p>
                <div className="col-md-4">
                    <a className="slide-btn" target="_blank" rel="noopener noreferrer" href="https://lumicstudio.shootproof.com/gallery/test_portfolio/">Portfolio</a>
                </div>
            </div>
        </SlidingWindow>
        <section id="community" className="container content">
            <i className="fa-solid fa-ear-listen content-icon d-block text-dark"/>
            <p className="top-title text-dark">Community Support</p>
            <div className="row">
                <div className="col-md-7">
                    <p>
                        Community is important to Lumic Studio. For local groups, finding money or the technical knowledge
                        to produce great images for your website or facebook group can be a challenge. We set aside time
                        each month to provide community groups with photography support.
                    </p>
                    <p>
                        If you have a community group that would like to request a professional photo session, get in-touch
                        to be assigned a slot within our community support program.
                    </p>
                    <a className="slide-btn" href="#contact">Contact Us!</a>
                </div>
                <div className="col-md-5 mt-4">
                    <img className="rounded border-dark img-shadow-dark" src="/community_1_25_percent.jpg" alt="Community"/>
                </div>
            </div>
        </section>
        <SlidingWindow id="wedding" image="/wedding_bg.jpg">
            <i className="fa-solid fa-ring content-icon d-block"/>
            <p className="top-title">Weddings</p>
            <p className="quote-text text-light">Getting engaged or married? We have a whole site, especially for you!</p>
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <a className="slide-btn" href="https://weddings.lumic-studio.com">Lumic Weddings</a>
                </div>
            </div>
        </SlidingWindow>
        <section id="contact" className="container content">
            <i className="fas fa-envelope-open-text content-icon d-block text-dark"/>
            <p className="top-title text-dark">Contact</p>
            <form id="contact-form"
                  name="contact-form"
                  action="mailto:wayne@lumic-studio.com"
                  method="POST"
                  encType="multipart/form-data"
            >
                <div className="form-group name">
                    <input type="text" className="form-control" name="name" id="name" placeholder="Your Name..."/>
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your EMail..."/>
                </div>
                <div className="form-group">
                    <textarea name="message" id="message" className="form-control" placeholder="Your Message..." rows="4"/>
                </div>
                <div className="clearfix text-left">
                    <button type="submit" className="btn btn-primary">Send Message</button>
                </div>
            </form>
            <div className="spacing40"/>
        </section>
        <Footer>
            <div className="row">
                <div className="col-6">
                    ©2022 Lumic Studio
                </div>
                <div className="col-6">
                    <ul className="footer-icon">
                        <li>
                            <a href="https://www.facebook.com/lumicstudio/" target="_blank" rel="noreferrer noopener">
                                <i className="fab fa-facebook-square"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/lumicstudio/" target="_blank" rel="noreferrer noopener">
                                <i className="fab fa-instagram"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com" target="_blank" rel="noreferrer noopener">
                                <i className="fab fa-youtube"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </Footer>
    </React.Fragment>

export default Application;
