import React from 'react';
import { addListener, removeListener, navigate, EVENT_SECTION_CHANGED, EVENT_TOP_CHANGED } from '../section_router';

const TRANSPARENT = "navbar fixed-top navbar-expand-lg navbar-dark nav-anim nav-transparent";
const SOLID = "navbar fixed-top navbar-expand-lg navbar-dark nav-anim";

const SECTION_HOME = '#home';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this._onRouteChanged = this._onRouteChanged.bind(this);
        this._onTopChanged = this._onTopChanged.bind(this);

        this.state = {
            activeHash: window.location.hash,
            pos: 0,
        };
    }

    navItemClass(hash) {
        return this.state.activeHash === hash ? 'btn btn-link nav-item nav-link active' : 'btn btn-link nav-item nav-link';
    }

    componentDidMount() {
        addListener(EVENT_SECTION_CHANGED, this._onRouteChanged);
        addListener(EVENT_TOP_CHANGED, this._onTopChanged);
    }

    componentWillUnmount() {
        removeListener(EVENT_TOP_CHANGED, this._onTopChanged);
        removeListener(EVENT_SECTION_CHANGED, this._onRouteChanged);
    }

    render() {
        return this._renderContent(this.props);
    }

    /**
     * Event handler invoked when the browsers route has been changed.
     * @private
     */
    _onRouteChanged() {
        this.setState({
            activeHash: window.location.hash,
        });
    }

    /**
     * Event handler invoked when the user has scrolled the display.
     * @param e
     * @private
     */
    _onTopChanged(e) {
        this.setState({
            pos: window.scrollY,
        });
    }

    _renderContent(props) {
        return (
            <nav className={this.state.pos ? SOLID : TRANSPARENT}>
                <a className="navbar-brand" href={SECTION_HOME}>Lumic Studio</a>
                <button className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${props.id}`}
                        aria-controls={props.id}
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>
                </button>
                <div id={props.id} className="collapse navbar-collapse">
                    <div className="navbar-nav ml-auto mt-2 mt-lg-0">
                        {props.items.map(item =>
                            <button className={this.navItemClass(`#${item.section}`)}
                                    onClick={() => navigate(`#${item.section}`)}
                            >
                                {item.title}
                            </button>
                        )}
                    </div>
                </div>
            </nav>
        );
    }
}
