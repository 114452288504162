import React from 'react';

const CarouselIndicators = props =>
    <div className="carousel-indicators">
        {props.items.map((item, index) =>
            <button type="button"
                    className={!index ? "active" : ""}
                    data-bs-target={`#${props.target}`}
                    data-bs-slide-to={`${index}`}
                    key={`indicator-${index}`}
            />
        )}
    </div>

export default CarouselIndicators;
