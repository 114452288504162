import React from 'react';

const SlidingWindow = props =>
    <section id={props.id} className="content sliding-window">
        <div className="bg-mask"/>
        <div className="linked-image" style={{backgroundImage: `url(${props.image})`}} />
        <div className="container content">
            {props.children}
        </div>
    </section>

export default SlidingWindow;
