import React from 'react';
import CarouselCaption from './caption';
import VideoBanner from '../video_banner';

const CarouselItem = props =>
    <div id={props.id} className={`carousel-item ${props.className}`}>
        <div className="overlay"/>
        {props.item.image ? <img className="d-block" src={props.item.image} alt={props.item.alt}/> : null}
        {props.item.video ? <VideoBanner src={props.item.video} /> : null}
        <CarouselCaption>
            {props.children}
        </CarouselCaption>
    </div>

export default CarouselItem;
